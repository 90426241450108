.content-home-carousel{
    width: 100%;
    height: calc(100vh - 101px);
    position: relative;
}
.carousel-item{
    height: calc(100vh - 101px);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.carousel-item-layer{
    height: calc(100vh - 101px);
    
    background-color: rgba(0,0,0,0.3);
}
.carousel-item-title{
    text-align: center;
    color: #fff;
    font-size: 2.5rem;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.555);
    font-family: Helvetica !important;
    font-weight: 700;
}
.carousel-button{
    width:auto;
    height:auto;
    padding: 10px 20px;
    background:#f36e03;
    border-radius: 25px;
    text-decoration:none;
    color: #fff !important;
}

/* ------------------------------------------ home content ------------------------------------- */

.home-content-section-one{
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}
.home-content-title{
    width: 100%;
    font-size: 2rem;
    text-align: center;
    margin-top:40px;
    color: #464646;
}
.featurette-heading{
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 800;
}
.home-info-item > p > a{
    color: #ed6e08;
    text-decoration: none;
}
.featurette-image{
    border-radius: 10px !important;
    height: auto;
    width: 300px;
}
.home-info-item > p{
    font-size: 15px;
    color: #5a5a5a;
}
.info-item-justificate{
    text-align: justify;
}
/* -------------------------------------------------------- home-content-section-two --------------------------------- */
.home-content-section-two{
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}
.content-section-title{
    width: 100%;
    margin-top: 40px;
    color: #464646;
    padding-bottom: 10px;
    border-bottom: 2px solid #f36e03;
}
.section-two-item-info >p{
    text-align: justify;
    color: #5a5a5a
}
.text-with-out-m >p{
    margin: 5px 0;
}
/* -------------------------------------------------------- home-content-section-3 --------------------------------- */
.home-content-section-3{
    width: 100%;
    min-height: auto;
}
.section-tree-item{
    margin-top: 40px;
}
.section-tree-item > p{
    color: #5a5a5a 
}
/* -------------------------------------------------------- media querys --------------------------------- */
@media (max-width: 768px) { 
    .home-content-section-one,.home-content-section-two, .home-content-section-3 {
        height: auto;
    } 
    .home-info-item >p{
        text-align: justify !important;
    }
    .carousel-item-title{
        font-size: 1.5rem;
    }
}
/* @media (min-width: 1200px){
    .container {
        width: 1200px !important;
    }
} */
@media(max-width: 991px){
    .content-home-carousel, .carousel-item, .carousel-item-layer{
        height: calc(100vh - 80px) !important;
    }

}
@media (max-width: 575.98px) {
    .carrousel-1{
        background-image: url('../../public/img/home-carrousel-mobile-1.webp') !important;
    }
    .carrousel-2{
        background-image: url('../../public/img/home-carrousel-mobile-2.webp') !important;
    }
    .carrousel-3{
        background-image: url('../../public/img/home-carrousel-mobile-3.webp') !important;
    }
    .carrousel-4{
        background-image: url('../../public/img/home-carrousel-mobile-4.webp') !important;
    }
    .carrousel-5{
        background-image: url('../../public/img/home-carrousel-mobile-5.webp') !important;
    }
}